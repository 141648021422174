import { useState } from 'react'

type UseSelectData<T> = {
  selected: T[]
  isSelected: (data: T) => boolean
  toggleSelect: (data: T) => void
}

export const useSelect = <T>(initialState?: T[]): UseSelectData<T> => {
  const [selected, setSelected] = useState<T[]>(initialState ?? [])

  const isSelected = (data: T): boolean => selected.includes(data)

  const toggleSelect = (data: T): void => {
    if (isSelected(data)) {
      setSelected((old) => {
        return old.filter((d) => d !== data)
      })
    } else {
      setSelected([...selected, data])
    }
  }

  return {
    selected,
    isSelected,
    toggleSelect
  }
}
