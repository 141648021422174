import React from 'react'

import { AuthLayout } from '@/presentation/components/layouts'
import { SignInView } from '@/presentation/views'

import { NextPageWithLayout } from './_app'

const SignIn: NextPageWithLayout = () => <SignInView />

SignIn.getLayout = (page) => <AuthLayout>{page}</AuthLayout>

export default SignIn
