import { useEffect, useState } from 'react'

import { NotificationProps, Notification } from '../components/ui'

export const useNotifications = () => {
  const [notifications, setNotifications] = useState<NotificationProps[]>([])

  const addNotification = (data: NotificationProps) => {
    setNotifications([...notifications, data])
  }

  const Notifications = () => (
    <>
      {notifications.map((notification, index) => (
        <Notification {...notification} key={index} />
      ))}
    </>
  )

  useEffect(() => {
    if (notifications.length > 0) {
      setTimeout(() => setNotifications([]), 2500)
    }
  }, [notifications])

  return {
    addNotification,
    Notifications
  }
}
